import React  from "react";
import { Modal, ModalHeader, ModalBody, Progress} from "reactstrap";


const Calculator = (props) => (
    <div>
      <Modal isOpen={props.progressCalculation}
      aria-labelledby="contained-modal-title-vcenter"
      centered 
      >
        {
          <div>
            <ModalHeader className="justify-content-center">
            <h3>Calculation in progress</h3>
            </ModalHeader>
              <ModalBody>
              <Progress animated value={100}
                style={{backgroundColor: "#42a7d7"}}
            />
              </ModalBody>
            </div>
        }
      </Modal>
    </div>
    )
export default Calculator;
