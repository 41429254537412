import React, {Component} from 'react';
import {Col, Row, Button, Form, FormGroup, Card} from "react-bootstrap";
import { ResultsField } from "./ResultsField";
import * as THREE from 'three';
import "./calculator.css";
import { FormCalculator } from "./FormCalculator.js";
import { VscHome, VscMail } from "react-icons/vsc";

function uuidv4() {
    // Author broofa
    // License MIT
    // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

class Calculator extends Component {
  constructor(props) {
  super(props);

  this.update_meshes = this.update_meshes.bind(this)
  this.updateGeometry = this.updateGeometry.bind(this)
  this.callData = this.callData.bind(this)
  this.inputOn = this.inputOn.bind(this)
  this.input_form_ref = React.createRef()
  this.input_form_ref_2 = React.createRef()
  this.callEmail = this.callEmail.bind(this)
  this.inputOff = this.inputOff.bind(this)
  this.state = {
    backend :
      (window.location + "").indexOf("localhost:30") === -1
        ? "https://lean.spindrive.eu"
        : "http://localhost:8080",
    deploy: (window.location + "").indexOf("localhost:30") === -1,
    separate_page: (window.location + "").indexOf("/magnetic-bearing-calculation/") !== -1,
    meshes: [],
    color: [],
    query_call: false,
    resultsOn: false,
    updater: 0,
    disabled: false,
    rotor_size: "",
    bearing_force: "",
    bearing_type: "",
    email:"",
    invalid_bearing_type: false,
    errors: {},
    span_x: 1,
    span_y: 1,
    dimensions: {outer_diameter: 100, stack_length: 50},
    design_faults: [],
    session_id: uuidv4()

  };
}

  update_meshes(json, updateGeometry) {
    const all_points = [];
    const all_colors = [];
    var points = [];
    var legend = {};
    var segment_params = {rotor_and_disc: {color: "#373434", name: "Rotor and disc"},
                          core: {color: "#5DC6C9", name: "Core"},
                          coils: {color: "#b87333", name: "Coils"},
                          rotor: {color: "#373434", name: "Rotor"},
                          journal: {color: "#3AABE1", name: "Journal"},
                          homopolar_radial_dead_leg: {color: "#5DC6C9", name: "Dead leg"},
                          permanent_magnet: {color: "#115588", name: "Permanent magnet"},
                          lamination_stator: {color: "#5DC6C9", name: "Lamination stator"},
                          solid_shaft: {color: "#373434", name: "Solid shaft"},
                          solid_stator_yoke: {color: "#485867", name: "Stator solid steel"},
                          lamination_rotor: {color: "#3AABE1", name: "Lamination rotor"}
                        }
    var min_x = null;
    var max_x = null;
    var min_y = null;
    var max_y = null;
    Object.keys(segment_params).map(x => {
      if (json.mesh[x])
      {
        points = [];
        json.mesh[x].map(p => {
            points.push( new THREE.Vector3( p[0], p[1], p[2] ) )
            if ((min_x === null) || (min_x > p[0]))
            {
              min_x = p[0];
            }
            if ((min_y === null) || (min_y > p[1]))
            {
              min_y = p[1];
            }
            if ((max_x === null) || (max_x < p[0]))
            {
              max_x = p[0];
            }
            if ((max_y === null) || (max_y < p[1]))
            {
              max_y = p[1];
            }
            return p;
            })
        all_points.push(points);
        all_colors.push(segment_params[x].color);
        legend[x] = segment_params[x];
      }
    })
    this.setState({span_x: max_x-min_x,
                   span_y: max_y-min_y,
                   legend: legend,
                   dimensions: json.dimensions,
                   design_faults: json.faults});
    console.log("done the res");
    setTimeout(() => updateGeometry(all_points, all_colors ),100);
}

  updateGeometry(new_values, all_colors) {
    var i = 0;
    var next_updater = this.state.updater;
    var mesh_set = []
    for (i = 0; i < new_values.length; i++)
    {
      next_updater++;
      mesh_set.push({points: new_values[i], key: next_updater})
    }
    console.log('mesh_set',mesh_set);
    this.setState({
      meshes:mesh_set,
      colors: all_colors,
      updater: next_updater,
      resultsOn: true,
    });
    console.log(this.input_form_ref_2.current.getBoundingClientRect().bottom);
    window.scroll({
        top: this.input_form_ref_2.current.getBoundingClientRect().bottom + window.scrollY,
        behavior: 'smooth'
     });
     setTimeout(() => this.setState({query_call:false}), 2000)
  };

  callData(bearing_type, rotor_diameter, max_force) {
    if (window.ldfdr)
    {
      window.ldfdr.pageview({ pageUrl: "https://spindrive.fi/calculator/"+bearing_type+"/"+parseFloat(rotor_diameter).toFixed(3)+"/"+parseFloat(max_force).toFixed(2)+"/"+this.state.session_id, pageTitle: "AMB calculator request" })
    }
    fetch(this.state.backend + "/v1/calculate/" + bearing_type + "/"+parseFloat(rotor_diameter).toFixed(3)+"/"+parseFloat(max_force).toFixed(2)+"/"+this.state.session_id)
        .then((response) => response.json())
        .then((json) => {
          console.log(json, "json");
          this.setState({
            bearing_type:bearing_type,
            rotor_diameter : rotor_diameter,
            bearing_force: max_force,
          })
          this.update_meshes(json, this.updateGeometry);
        });
  }

  callEmail(email) {
    if (window.ldfdr)
    {
      window.ldfdr.pageview({ pageUrl: "https://spindrive.fi/calculator/"+this.state.bearing_type+"/"+parseFloat(this.state.rotor_diameter).toFixed(3)+"/"+this.state.bearing_force.toFixed(2) +"/"+ email+"/"+this.state.session_id, pageTitle: "AMB calculator request report" })
    }
    fetch(this.state.backend + '/v1/report/'+ this.state.bearing_type +'/'+ parseFloat(this.state.rotor_diameter).toFixed(3)+"/"+this.state.bearing_force.toFixed(2) +"/"+ email+"/"+this.state.session_id
    +'/'+ this.state.bearing_force +'/'+ email)
  }

  inputOff(){
    this.setState({
      disabled: true,
      query_call:true,
    })
  }
  inputOn() {
    this.setState({
      disabled:false,
    resultsOn: false,})
  }

render(){
  const rotation = new THREE.Euler(0,0,0); //Math.PI/3

return (
  <div style={{paddingBottom:"40px"}}>
    <div className="w-100 d-flex flex-column align-items-center p-2">

      <Row className="justify-content-center"
        style={{marginBottom: "1.25rem"}}
        ref={this.input_form_ref}>

        <h1
          style={{fontSize:"40px", lineHeight: "38px", textAlign:"center"}}>
          Calculate your active magnetic bearing actuators
        </h1>
      </Row>

      <Row md={12} sm={12} >
        <Col xs={12} sm={4}>
          <Card style={{border: 0}} >
             <Card.Img top width="100%" className="amb-cards" src={this.state.backend + "/static/media/axial-magnetic-bearing.jpg"} alt="C-shaped axial AMB" />
             <Card.Body>
               <Card.Title tag="h5"><center>Axial magnetic bearing </center></Card.Title>
             </Card.Body>
           </Card>
         </Col>
          <Col xs={12} sm={4}>
            <Card style={{border: 0}}>
               <Card.Img top width="100%" className="amb-cards" src={this.state.backend + "/static/media/radial-magnetic-bearing-with-permanent-magnet.jpg"} alt="Homopolar Bearing" />
               <Card.Body>
                 <Card.Title tag="h5"><center>Radial magnetic bearing with permanent magnet</center></Card.Title>
               </Card.Body>
             </Card>
           </Col>
           <Col xs={12} sm={4}>
             <Card style={{border: 0}}>
                <Card.Img top width="100%" className="amb-cards" src={this.state.backend + "/static/media/radial-magnetic-bearing.jpg"} alt="Heteropolar Radial AMB" />
                <Card.Body>
                  <Card.Title tag="h5"><center>Radial heteropolar magnetic bearing</center></Card.Title>
                </Card.Body>
              </Card>
            </Col>
      </Row>

        {/*disabled={this.state.disabled}*/}
      <FormCalculator
        disabled={false}
        query_call = {this.state.query_call}
        inputOff = {this.inputOff}
        callData= {this.callData}
        separatePage = {this.state.separate_page}
      />

      <div
        ref={this.input_form_ref_2}></div>
      {this.state.resultsOn
        ? <Col size={12} className="p-0"><ResultsField
            meshes={this.state.meshes}
            design_faults={this.state.design_faults}
            rotation={this.state.rotation}
            colors={this.state.colors}
            legend = {this.state.legend}
            dimensions = {this.state.dimensions}
            inputOn = {this.inputOn}
            spanX = {this.state.span_x}
            spanY = {this.state.span_y}
            ref={this.result_ref}
            input_form_ref={this.input_form_ref}
            callEmail={this.callEmail}

          />

          </Col>
        : null}
      {this.state.separate_page || !this.state.deploy ?
        <div className="d-flex flex-row flex-wrap justify-content-center w-100" >
          <div className="col-md-12 col-lg-4 p-1">
            <Button className="w-100 m-1"
              onClick={() => {window.location.href = '/#subscribe'}}
              >
              <VscMail/>  Subscribe
            </Button>
          </div>
          <div className="col-md-12 col-lg-4 p-1">
            <Button className="w-100 m-1"
              onClick={() => {window.location.href = '/'}}
              >
                <VscHome/> Return to Homepage
            </Button>
          </div>
        </div>: null
      }
    </div>
  </div>
    );
  }
}
export default Calculator;
