import React from "react";
import { VscTools } from "react-icons/vsc";
import {Col, Button,Form, FormGroup} from "react-bootstrap";
import { Formik} from 'formik';
import * as yup from 'yup';
import CalculationProgress from "./CalculationProgress.js";

export const FormCalculator = (props) => {
  const schema = yup.object({
    bearing_type: yup.string()
                     .required('Bearing type must be selected')
                     .test('len', 'Bearing type must be selected', v => v !== "Select bearing type"),
    rotor_size: yup.number()
                   .required("Rotor size must be entered")
                   .positive("Negative values cannot be used")
                   .min(10, "In typical industrial application the diameter of radial AMB is in range of 30...300 mm and the radial force 4...5 times the gravity."),
    bearing_force: yup.number()
                      .required("Bearing force must be entered")
                      .positive("Negative values cannot be used")
                      .min(10, "In typical industrial application the diameter of radial AMB is in range of 30...300 mm and the radial force 4...5 times the gravity."),
  });
    return(
<div>

        <Formik
            validationSchema={schema}
            onSubmit={values => {
              console.log(values);
              console.log("Formik onSubmit");
              props.inputOff();
              props.callData(values.bearing_type, values.rotor_size, values.bearing_force)
            }}
            initialValues={{
              bearing_type: "",
              bearing_force: "",
              rotor_size: ""}}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              validateForm
            }) => (
          <Form
            outsideVariable = {values.bearing_type}
            onSubmit={(e)=>{
              e.preventDefault();
              console.log("Form onSubmit")
              validateForm().then(() => {
                console.log(errors)
                if (!(errors.bearing_type || errors.rotor_size || errors.bearing_force ))
                {
                  handleSubmit();
                }
              })}}>

            <Form.Row
              className="justify-content-center align-items-center"
              style={{paddingBottom:"40px"}}>

              <Col lg={3}
                  style={{alignSelf:"flex-end"}}>
                <Form.Group style={{textAlign:"center"}}>
                  <Form.Label  className="text">Magnetic bearing type</Form.Label>
                  <Form.Control as="select"
                    name="bearing_type"
                    value={values.bearing_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled = {(
                      props.disabled)
                      ? "disabled"
                      : ""}
                    isInvalid={touched.bearing_type && errors.bearing_type}
                    >
                    <option >Select magnetic bearing type</option>
                    <option value="axial">Axial</option>
                    <option value="homopolar">Radial with permanent magnet</option>
                    <option value="heteropolar">Radial heteropolar</option>
                  </Form.Control>

                  <Form.Control.Feedback type="invalid" tooltip
                    className="invalid-tooltip-error">
                      {errors.bearing_type}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                lg={2}
                style={{alignSelf:"flex-end"}}>

                <Form.Group style={{textAlign:"center"}}>
                  <Form.Label
                    className="mr-sm-2 text">
                        Rotor Diameter, mm
                  </Form.Label>

                  <Form.Control
                    type="number"
                    name="rotor_size"
                    value={values.rotor_size}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.rotor_size && errors.rotor_size}
                    disabled = {(
                      props.disabled)
                      ? "disabled"
                      : ""}
                      />

                  <Form.Control.Feedback type="invalid" tooltip
                  className="invalid-tooltip-error">
                    {errors.rotor_size}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col
                lg={2}
                style={{alignSelf:"flex-end"}}>
                <FormGroup
                  style={{textAlign:"center"}}>
                  <Form.Label
                    for="exampleForce"
                    className="mr-sm-2 text">
                      Peak Bearing Force, N
                  </Form.Label>

                  <Form.Control
                    type="number"
                    name="bearing_force"
                    value={values.bearing_force}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.bearing_force && errors.bearing_force}
                    disabled = {(
                      props.disabled)
                      ? "disabled"
                      : ""}
                      />
                  <Form.Control.Feedback
                  type="invalid" tooltip
                  className="invalid-tooltip-error">
                    {errors.bearing_force}
                  </Form.Control.Feedback>
                </FormGroup>
              </Col>

              <Col lg={2} style={{alignSelf:"flex-end"}}>
                <FormGroup>
                  <Button
                    type="submit"
                    id="submit-info"
                    style={{width:"100%"}}
                    disabled = {(
                      isValid)
                      ? ""
                      : "disabled"}
                    >
                    <b><VscTools/> Calculate</b>
                  </Button>

                  {props.query_call
                  ? <CalculationProgress progressCalculation={props.query_call}
                  />
                : null
                }
                  </FormGroup>
                </Col>
            </Form.Row>
          </Form>
        )}
      </Formik>
  </div>
  )
}
