import React, {useState} from "react";
import { BiMailSend } from "react-icons/bi";
import { RiArrowGoBackLine } from "react-icons/ri";
import {Col, Row, Button, Form, Table  } from "react-bootstrap";
import CanvasBox from "./CanvasBox";
import * as Yup from 'yup';
import { Formik } from 'formik';
import  SendInfo  from "./SendInfo.js";



export const ResultsField = (props) => {
  const [sendMail, setSendMail] = useState(false)
  const errorSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Invalid email"),
  });
  return (
    <div>
        <div style={{paddingTop: "40px", paddingBottom:"40px"}}>
          <Row className="d-flex justify-content-center"
              style={{marginBottom: "1.25rem"}}>
            <h1 style={{fontSize:"40px", lineHeight: "38px",  textAlign:"center"}}>
              Results of calculation
            </h1>
          </Row>
          {props.design_faults.length === 0 ?(
            <div>
            <Row className="justify-content-center text">
            Outer diameter {props.dimensions.outer_diameter.toFixed(0)} mm. Overall axial length: {props.dimensions.stack_length.toFixed(0)} mm
            </Row>
            <Row className="justify-content-start">
              <Col xl={8} lg={8} md={12}style={{alignSelf:"flex-center", padding: 0}}>
                <CanvasBox
                  meshes={props.meshes}
                  rotation={props.rotation}
                  colors={props.colors}
                  spanX={props.spanX}
                  spanY={props.spanY}/>
              </Col>
              <Col xl={2} lg={2} md={12} className="align-self-center justify-content-center mesh-legend-column">
                <center>
                <Col xl={12} lg={12} md={4} sm={6} xs={11}>
                <center><h3>Legend</h3></center>
                  {Object.keys(props.legend).map((x, index) => (
                    <Row className="justify-content-start align-items-center ">
                        <div className="square"
                          style={{backgroundColor:props.legend[x].color}}> {/*, filter: "opacity(0.55)"*/}
                        </div>
                      <div className="text" style={{padding:"0.25rem"}}>{props.legend[x].name}</div>
                    </Row>
                  ))}
                </Col>
                  </center>
              </Col>
            </Row>
            </div>
          ) : null}
          {props.design_faults.length > 0 ?(
            <div>
              <Row className="justify-content-center text">
              {props.design_faults.map(x => (<Col sm={8}
                dangerouslySetInnerHTML={{__html:x.replace("<email>", '<a href="mailto:info@spindrive.fi">info@spindrive.fi</a>')}}></Col>))}
              </Row>
              <br></br>
            </div>
          ) : null}
          {/*
          <Row className="d-flex justify-content-center">
            <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={values => {
                console.log(values);
              }}
              validationSchema={errorSchema}
              >
              {({
                errors,
                handleChange,
                handleSubmit,
                isValid,
                values,
                touched,
                handleBlur
              }) => (

            <Form
              onSubmit={values =>{
                console.log(values)
              }
            }>
              <Form.Row className="justify-content-center">
              <Col lg="auto">
                <Form.Group>

                    <Form.Control
                      className="mb-2 mr-sm-2"

                      type="email"
                      id="email"
                      name="email"
                      placeholder="E-mail"
                      value={values.email}
                      onChange={handleChange}
                      onBlur = {handleBlur}
                      isInvalid={touched.email && errors.email}/>

                    <Form.Control.Feedback
                        type="invalid" tooltip
                        className="invalid-tooltip-error">
                        {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  </Col>

                  <Col lg="auto" md="auto" sm="auto">
                  <Form.Group>
                    <Button
                    className="mb-2 mr-sm-2"
                      id="submit-info"

                      onClick={() => {
                        props.callEmail(values.email);
                        setSendMail(true)}}
                      disabled={
                        (isValid)
                        ? ""
                        : "disabled"
                      }
                      >
                        <b><BiMailSend /> Send full report to e-mail</b>
                    </Button>
                    </Form.Group>
                    </Col>
                      {sendMail
                      ? <SendInfo sendMail = {sendMail}
                      setSendMail = {setSendMail}
                      inputOn={props.inputOn}
                      ref={props.result_ref}
                      input_form_ref={props.input_form_ref}
                      />
                      : null
                      }
              </Form.Row>
            </Form>
            )}
            </Formik>
        </Row>*/}
        <Row className="d-flex justify-content-center">
        <Col lg="auto">
          <Button
            className ="button-submit mb-2 mr-sm-2"
            style={{textAlign:"center"}}
            id="submit-info"
            style={{backgroundColor:"#0E67ADF5", borderColor:"#0E67ADF5", width:"100%"}}
            onClick={() => {
              console.log(props.input_form_ref.current.getBoundingClientRect().top)
              window.scroll({
                  top: props.input_form_ref.current.getBoundingClientRect().top + window.scrollY - 50,
                  behavior: 'smooth'
               });
              props.inputOn();
            }
          }>
            <b><RiArrowGoBackLine /> Back to calculation</b>
          </Button>
          </Col>
        </Row>

      </div>
      </div>
    )
  }
