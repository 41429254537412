import React, {useRef, useState, useEffect} from "react";
import { Canvas, useFrame } from 'react-three-fiber';
import * as THREE from 'three';
import Box from "./Box";
import Mesh from "./Mesh";

function CanvasBox(props) {
    let canvasRef = useRef(null);

    const [ratio, setRatio] = React.useState(1);

    const getRatio = () => (!canvasRef.current ? 1 : canvasRef.current.clientWidth / canvasRef.current.clientHeight);

    const resizeEvent = () => {
      console.log("canvas resize event", canvasRef.current)
        // canvasRef.current.width = canvasRef.current.clientWidth;
        // canvasRef.current.height = canvasRef.current.clientHeight;
        setRatio(getRatio());
    };

    React.useEffect(() => {
      resizeEvent()
    })
    React.useEffect(() => {
      if (canvasRef)
      {
        window.addEventListener("resize", resizeEvent);
        return () => window.removeEventListener("resize", resizeEvent);
      }
    });

    return (
      <div ref={canvasRef} style={{width:"100%", height: "50vh", padding: 0}}>
        <Canvas style={{width:"100%", height: "100%", padding: 0}}
          colorManagement={false}
        camera={{ position: [0, 0, 5], near: 0.00000001, far: 10, fov: 8 }}>
            <ambientLight ambientLight={0.5} color={0xFFFFFF}/>
            {/*<pointLight position={[10, 10, 10]} />*/}
                {
                props.meshes.map((m, i) =>
                (<Mesh
                  canvas_ref={canvasRef}
                  canvasRatio={ratio}
                  key={m.key}
                  key_val={m.key}
                  rotation={props.rotation}
                  geometry={new THREE.BufferGeometry().setFromPoints( m.points)}
                  color={props.colors[i]}
                  spanX={props.spanX}
                  spanY={props.spanY}
                  />))
            }
          </Canvas>
        </div>
      )
}

export default CanvasBox;
