import React, {useRef, useState} from "react";
import * as THREE from 'three';

function Mesh(props)
{
    console.log("Mesh", props.key_val)
    console.log("canvas_ref", props.canvas_ref)
    var max_span = props.spanX;
    var scale_base = 0.5;

    if (props.canvasRatio < 1)
    {
      scale_base = 0.5 * props.canvasRatio;
    }

    if (props.spanY > props.spanX)
    {
      max_span = props.spanY
    }

    return (<mesh
              {...props}
              visible
              position={new THREE.Vector3(0, 0, 0)}
              scale={[scale_base/max_span,scale_base/max_span,0]}
              material={new THREE.MeshBasicMaterial({ color: new THREE.Color(props.color),
                                                      transparent: false,
                                                      reflectivity: 0,
                                                      lightMapIntensity: 0,
                                                      aoMapIntensity: 0,
                                                      opacity: 1,
                                                      metalness: 0})}
            />)
}

export default Mesh;
